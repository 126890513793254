import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

import { WindowRef } from '@progress/kendo-angular-dialog';

import {
  Resource,
  EditorEvent,
  WizardConfig,
  AttributeResource,
  WizardEvent,
  BroadcastEvent,
} from '../../models/dataContract.model';
import { ObjectView } from '../../models/dynamicEditor.interface';

import { ConfigService } from '../../services/config.service';

import { AttributeViewComponent } from '../attribute-view/attribute-view.component';

@Component({
  selector: 'app-popup-wizard',
  templateUrl: './popup-wizard.component.html',
  styleUrls: ['./popup-wizard.component.scss'],
})
export class PopupWizardComponent
  extends ObjectView
  implements OnInit, AfterViewInit, OnDestroy
{
  private windowRef: WindowRef;

  @ViewChild('stepper')
  stepper: MatStepper;

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @ViewChildren('attributeView')
  attributeViews: QueryList<AttributeViewComponent>;

  @Input()
  dialogMode = true;

  @Input()
  configMode = false;

  viewSetting: any;
  hasViewSetting = true;

  isFinished = false;

  actionAfterCreation = 'stay';

  resourceToCreate: Resource;

  leftPadding = 0;

  get currentIndex(): number {
    return this.stepper ? this.stepper.selectedIndex : -1;
  }

  get isFirstStep() {
    if (this.stepper) {
      return this.stepper.selectedIndex === 0;
    } else {
      return true;
    }
  }

  get isLastStep() {
    if (this.stepper) {
      return this.stepper.selectedIndex === this.stepper.steps.length - 1;
    } else {
      return false;
    }
  }

  data: {
    config?: WizardConfig;
    schema?: Resource;
    parameters?: { [name: string]: string };
  } = {};

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private config: ConfigService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.data.config) {
      if (this.data.config.navigateToResource === true) {
        this.actionAfterCreation = 'navigate';
      } else if (this.data.config.navigateToResource === false) {
        this.actionAfterCreation = 'stay';
      } else {
        this.actionAfterCreation = this.config.getConfig(
          'navigateAfterCreation',
          false
        )
          ? 'navigate'
          : 'stay';
      }
    } else {
      this.actionAfterCreation = this.config.getConfig(
        'navigateAfterCreation',
        false
      )
        ? 'navigate'
        : 'stay';
    }

    this.subscription.add(
      this.swap.onEditorEvent.subscribe((event: EditorEvent) => {
        this.swap.wizardEvent(new WizardEvent('editor', this, null, event));
      })
    );

    this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
      if (event) {
        switch (event.name) {
          case 'close-popup-wizard':
            this.onClose();
            break;
          case 'cancel-popup-wizard':
            this.onCancel();
            break;
          default:
            break;
        }
      }
    });

    this.initWizard();
  }

  ngAfterViewInit() {
    if (this.dialogMode) {
      this.windowRef = this.injector.get(WindowRef);
      if (this.windowRef) {
        this.windowRef.window.instance.stateChange.subscribe((state: any) => {
          if (state === 'maximized') {
            this.leftPadding = 60;
          }
          if (state === 'default') {
            this.leftPadding = 0;
          }
        });
        if (this.titleBar) {
          this.windowRef.window.instance.titleBarTemplate = this.titleBar;
        }
      }
    }

    if (this.stepper) {
      this.isFinished =
        this.stepper.selectedIndex === this.stepper.steps.length - 1;
      this.cdr.detectChanges();
    }

    // this.swap.wizardEvent(new WizardEvent('afterInit', this));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initWizard(config?: WizardConfig, objectSchema?: Resource) {
    if (this.stepper) {
      this.stepper.selectedIndex = 0;
    }

    // this.data.config = undefined;
    if (config && config.name && config.objectType) {
      this.data.config = config;
    }
    if (objectSchema) {
      this.data.schema = objectSchema;
    }

    let viewName = '';
    if (this.data && this.data.config) {
      if (this.data.config.name) {
        viewName = this.data.config.name.toLowerCase();
      } else if (this.data.config.objectType) {
        viewName = this.data.config.objectType.toLowerCase();
      } else {
        return;
      }
    }

    this.hasViewSetting =
      this.data &&
      this.data.config &&
      this.initComponent('creationView', viewName);

    if (!this.hasViewSetting) {
      return;
    }

    if (this.configMode) {
      this.resourceToCreate = this.data.schema;
      Object.keys(this.resourceToCreate).forEach((key) => {
        this.resourceToCreate[key].permissionHint =
          'Add, Create, Modify, Delete, Read, Remove';
        this.resourceToCreate[key].value = '';
        this.resourceToCreate[key].values = [];
      });
      this.onEditSetting();
    } else {
      this.resourceToCreate = {};
      this.viewSetting.sections.forEach((section: any) => {
        section.attributes.forEach((attributeDef: any) => {
          const attribute = this.utils.ExtraValue(
            this.data.schema,
            attributeDef.attributeName
          );
          if (attribute && !this.resourceToCreate[attributeDef.attributeName]) {
            attribute.permissionHint =
              'Add, Create, Modify, Delete, Read, Remove';
            attribute.value = '';
            attribute.values = [];
            this.resourceToCreate[attributeDef.attributeName] = attribute;
          }
        });
      });
      if (!this.resourceToCreate.ObjectType) {
        const attObjectType = new AttributeResource();
        attObjectType.dataType = 'String';
        attObjectType.displayName = 'Object Type';
        attObjectType.systemName = 'ObjectType';
        attObjectType.value = this.data.config.objectType;
        this.resourceToCreate.ObjectType = attObjectType;
      }
    }
  }

  getEditor(attributeName: string, tabName?: string) {
    if (this.attributeViews) {
      if (tabName) {
        const attrView = this.attributeViews.find(
          (a) => a.tabName && a.tabName.toLowerCase() === tabName.toLowerCase()
        );
        if (attrView) {
          return attrView.getEditor(attributeName);
        }
      } else {
        for (const view of this.attributeViews) {
          const editor = view.getEditor(attributeName);
          if (editor) {
            return editor;
          }
        }
      }
    }

    return null;
  }

  hasFinished() {
    if (this.currentIndex >= 0) {
      if (
        this.data.config.canSubmitAtStep &&
        this.currentIndex < this.data.config.canSubmitAtStep
      ) {
        return false;
      }

      const stepName = this.viewSetting.sections[this.currentIndex].name;
      if (this.viewResults[stepName] && this.viewResults[stepName].length > 0) {
        return (
          this.viewResults[stepName].findIndex(
            (t) => t.config.required && !t.controller.value
          ) < 0
        );
      }
    }

    return false;
  }

  stepHasError() {
    if (this.currentIndex >= 0) {
      const stepName = this.viewSetting.sections[this.currentIndex].name;
      if (this.viewResults[stepName] && this.viewResults[stepName].length > 0) {
        return (
          this.viewResults[stepName].findIndex((t) => {
            if (this.attributeViews) {
              const editor = this.getEditor(t.attribute.systemName);
              return t.controller.valid === false && editor;
            } else {
              return t.controller.valid === false;
            }
          }) >= 0
        );
      }
    }

    return false;
  }

  wizardHasError() {
    for (const key in this.viewResults) {
      if (this.viewResults.hasOwnProperty(key)) {
        const pos = this.viewResults[key].findIndex((t) => {
          if (this.attributeViews) {
            const editor = this.getEditor(t.attribute.systemName);
            return t.controller.valid === false && editor;
          } else {
            return t.controller.valid === false;
          }
        });
        if (pos >= 0) {
          return true;
        }
      }
    }

    return false;
  }

  onNext() {
    if (this.stepper) {
      this.stepper.next();
      this.isFinished =
        this.stepper.selectedIndex === this.stepper.steps.length - 1;

      if (this.attributeViews) {
        const view = this.attributeViews.get(this.currentIndex);
        if (view) {
          view.resetDisplayOptions();
        }
      }
    }
  }

  onBack() {
    if (this.stepper) {
      this.stepper.previous();

      if (this.attributeViews) {
        const view = this.attributeViews.get(this.currentIndex);
        if (view) {
          view.resetDisplayOptions();
        }
      }
    }
  }

  onClose() {
    if (this.windowRef) {
      this.swap.wizardEvent(
        new WizardEvent('beforeClose', this, this.windowRef, {
          resource: this.resourceToCreate,
          navigate: this.actionAfterCreation === 'stay' ? false : true,
          navigationKey:
            this.data && this.data.config
              ? this.data.config.navigationKey
              : null,
        })
      );
    }
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }
}
