import {
  Component,
  OnInit,
  forwardRef,
  Injector,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
  ViewChild,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormControl,
  NgControl,
} from '@angular/forms';

import { of, Observable, Subscription } from 'rxjs';
import { tap, switchMap, finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';

import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { TextEditorConfig } from '../../models/editorContract.model';

import { createTextEditorValidator } from '../../validators/validators';

import { SwapService } from '../../services/swap.service';

import { EditorTextConfigComponent } from './editor-text-config.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';
import {
  EditorEvent,
  ExpressionValidationSpecification,
  ModelUpdateMode,
  ValidationSetting,
  ValidationTag,
} from '../../models/dataContract.model';
import { ConfigService } from '../../services/config.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-editor-text',
  templateUrl: './editor-text.component.html',
  styleUrls: ['./editor-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorTextComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorTextComponent),
      multi: true,
    },
  ],
})
export class EditorTextComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChildren(MatMenuTrigger) tagMenuTriggers: QueryList<MatMenuTrigger>;

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger: MatAutocompleteTrigger;

  @Input()
  loadAsReadonly = false;

  @Output()
  addValue = new EventEmitter<any>();

  @Output()
  removeValue = new EventEmitter<any>();

  private subscription: Subscription = new Subscription();

  private conf = new TextEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  autoCompleteOptions: Observable<Array<{ text: string; value: string }>>;

  hidden = true;

  loadingValidationInfo = false;

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  modelUpdateOn: ModelUpdateMode = this.configService.getConfig(
    'modelUpdateOn',
    'change'
  );

  validationSetting: ValidationSetting;

  simpleEditing = false;

  private prepareTags(info: ExpressionValidationSpecification) {
    this.validationSetting = new ValidationSetting({
      type: 'tags',
      allowedLookups: info.allowedLookups,
      allowedStringFormats: info.allowedStringFormats,
    });
    if (info.defaultExpressionType === 'Custom') {
      const validationTag: ValidationTag = {
        name: 'custom',
        abbreviation: 'V',
        description: 'Custom validation from backend',
        descLong: 'Custom validation from backend',
        helpUrl: this.configService.getConfig('helpLinkUrl'),
        isActive: true,
      };
      this.validationSetting.tags = [validationTag];
    } else {
      if (info.allowFunctionExpression) {
        const validationTag: ValidationTag = {
          name: 'function',
          abbreviation: 'FN',
          description: 'key_allowFunctionExpression',
          descLong: 'key_allowFunctionExpressionLong',
          helpUrl: this.configService.getConfig('helpLinkUrl'),
        };
        if (
          info.currentExpressionType === 'FunctionExpression' ||
          (!info.currentExpressionType &&
            info.defaultExpressionType === 'FunctionExpression')
        ) {
          validationTag.isActive = true;
        }
        if (
          this.validationSetting.tags &&
          this.validationSetting.tags.length >= 0
        ) {
          this.validationSetting.tags.push(validationTag);
        } else {
          this.validationSetting.tags = [validationTag];
        }
      }
      if (info.allowInterpolatedString) {
        const validationTag: ValidationTag = {
          name: 'string',
          abbreviation: 'IS',
          description: 'key_allowInterpolatedString',
          descLong: 'key_allowInterpolatedStringLong',
          helpUrl: this.configService.getConfig('helpLinkUrl'),
        };
        if (
          info.currentExpressionType === 'InterpolatedString' ||
          (!info.currentExpressionType &&
            info.defaultExpressionType === 'InterpolatedString')
        ) {
          validationTag.isActive = true;
        }
        if (
          this.validationSetting.tags &&
          this.validationSetting.tags.length >= 0
        ) {
          this.validationSetting.tags.push(validationTag);
        } else {
          this.validationSetting.tags = [validationTag];
        }
      }
      if (info.allowXPath) {
        const validationTag: ValidationTag = {
          name: info.xPathMustBeSimple ? 'simplexpath' : 'xpath',
          abbreviation: info.xPathMustBeSimple ? 'SXP' : 'XP',
          helpUrl: this.configService.getConfig('helpLinkUrl'),
          description: info.xPathMustBeSimple
            ? 'key_allowSimpleXpath'
            : 'key_allowXpath',
          descLong: info.xPathMustBeSimple
            ? 'key_allowSimpleXpathLong'
            : 'key_allowXpathLong',
        };
        if (
          info.currentExpressionType === 'XPath' ||
          (!info.currentExpressionType &&
            info.defaultExpressionType === 'XPath')
        ) {
          validationTag.isActive = true;
        }
        if (
          this.validationSetting.tags &&
          this.validationSetting.tags.length >= 0
        ) {
          this.validationSetting.tags.push(validationTag);
        } else {
          this.validationSetting.tags = [validationTag];
        }
      }
    }
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService
  ) {
    super(injector);
  }

  get value() {
    if (this.simpleMode) {
      return this.config.isMultivalue
        ? this.editorAttribute.values
        : this.editorAttribute.value;
    } else {
      if (this.config.isSimpleValue) {
        return this.editorAttribute;
      } else {
        if (
          this.editorAttribute &&
          this.editorAttribute.value !== null &&
          this.editorAttribute.value !== undefined
        ) {
          if (this.config.isMultivalue) {
            if (this.editorAttribute.dataType === 'Reference') {
              return this.editorAttribute.values.map((v) =>
                this.utils.ExtraValue(v, 'ObjectID')
              );
            } else {
              return this.editorAttribute.values;
            }
          } else {
            if (this.editorAttribute.dataType === 'Reference') {
              if (typeof this.editorAttribute.value === 'string') {
                return this.editorAttribute.value;
              } else {
                return this.utils.ExtraValue(
                  this.editorAttribute.value,
                  'ObjectID'
                );
              }
            } else {
              if (this.config.savePrefix && this.config.prefix) {
                if (this.editorAttribute.value.startsWith(this.config.prefix)) {
                  return this.editorAttribute.value.substring(
                    this.config.prefix.length
                  );
                } else {
                  return this.editorAttribute.value;
                }
              }
              return this.editorAttribute.value;
            }
          }
        } else {
          return null;
        }
      }
    }
  }
  set value(value) {
    if (this.simpleMode) {
      if (this.config.isMultivalue) {
        this.editorAttribute.values = value;
        if (value && value.length > 0) {
          this.editorAttribute.value = value[0];
        }
      } else {
        this.editorAttribute.value = value;
      }
    } else {
      if (this.config.isSimpleValue) {
        this.editorAttribute = value;
      } else {
        if (this.config.isMultivalue) {
          this.editorAttribute.values = value;
          if (value && value.length > 0) {
            this.editorAttribute.value = value[0];
          }
        } else {
          if (this.config.savePrefix && this.config.prefix) {
            if (value.startsWith(this.config.prefix)) {
              this.editorAttribute.value = value;
            } else {
              if (this.config.noPrefixIfEmpty) {
                this.editorAttribute.value = value
                  ? this.config.prefix + value
                  : '';
              } else {
                this.editorAttribute.value = this.config.prefix + value;
              }
            }
          } else {
            this.editorAttribute.value = value;
          }
        }
      }
    }

    this.propagateChange(this.editorAttribute);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.initComponent();
    }
  }

  ngAfterViewInit() {
    if (this.simpleMode) {
      this.setDisplay(null, true);
    } else {
      setTimeout(() => {
        // const ngControl: NgControl = this.injector.get<NgControl>(
        //   NgControl as Type<NgControl>
        // );
        const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
        if (ngControl) {
          this.control = ngControl.control as FormControl;
        }

        if (this.config) {
          if (this.config.validationKey) {
            this.loadingValidationInfo = true;
            this.subscription.add(
              this.resource
                .getValidationInfo(this.config.validationKey, this.value)
                .pipe(
                  tap((info: ExpressionValidationSpecification) => {
                    this.prepareTags(info);
                  }),
                  finalize(() => {
                    this.loadingValidationInfo = false;
                  })
                )
                .subscribe()
            );
          } else {
            if (this.config.validationSetting) {
              if (
                this.config.validationSetting.type === 'key' &&
                this.config.validationSetting.key
              ) {
                this.loadingValidationInfo = true;
                this.subscription.add(
                  this.resource
                    .getValidationInfo(
                      this.config.validationSetting.key,
                      this.value
                    )
                    .pipe(
                      tap((info: ExpressionValidationSpecification) => {
                        this.prepareTags(info);
                      }),
                      finalize(() => {
                        this.loadingValidationInfo = false;
                      })
                    )
                    .subscribe()
                );
              } else if (
                this.config.validationSetting.type === 'tags' &&
                this.config.validationSetting.tags
              ) {
                this.validationSetting = this.utils.DeepCopy(
                  this.config.validationSetting
                );
              }
            }
          }
        }
      });

      setTimeout(() => {
        this.validationFn = createTextEditorValidator(this.config);
        this.applyConfig();

        if (!this.configMode) {
          if (this.creationMode) {
            this.validateValue();
          } else if (
            this.configService.getConfig('validateBeforeEditing', false)
          ) {
            this.validateValue();
          }
        }

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            this.value = this.resolveExpression(this.config.initExpression);
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddValue(event: MatChipInputEvent) {
    if (event && event.value) {
      const inputValue = event.value.trim();

      if (
        this.editorAttribute.values &&
        this.editorAttribute.values.length > 0
      ) {
        const index = this.editorAttribute.values.findIndex((a: string) => {
          return this.config.caseSensitive
            ? a === inputValue
            : a.toLowerCase() === inputValue.toLowerCase();
        });
        if (index < 0) {
          this.editorAttribute.values.push(inputValue);
          this.propagateChange(this.editorAttribute);
          if (this.addValue.observers.length > 0) {
            this.addValue.emit(inputValue);
          }
          this.swap.editorEvent(
            new EditorEvent(
              'addValue',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              inputValue
            )
          );
        }
      } else {
        this.editorAttribute.values = [inputValue];
        this.editorAttribute.value = inputValue;
        this.propagateChange(this.editorAttribute);
        if (this.addValue.observers.length > 0) {
          this.addValue.emit(inputValue);
        }
        this.swap.editorEvent(
          new EditorEvent(
            'addValue',
            this.config.attributeName,
            this.currentID,
            this.currentType,
            inputValue
          )
        );
      }
    }

    if (event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }
  }

  onRemoveValue(deleteValue: string) {
    const index = this.editorAttribute.values.indexOf(deleteValue);
    if (index >= 0) {
      this.editorAttribute.values.splice(index, 1);
      this.propagateChange(this.editorAttribute);
      if (this.removeValue.observers.length > 0) {
        this.removeValue.emit(deleteValue);
      }
      this.swap.editorEvent(
        new EditorEvent(
          'removeValue',
          this.config.attributeName,
          this.currentID,
          this.currentType,
          deleteValue
        )
      );
    }
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new TextEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    if (this.config.updateOn) {
      if (
        String(this.config.updateOn) !== 'null' &&
        String(this.config.updateOn) !== 'undefined'
      ) {
        this.modelUpdateOn = this.config.updateOn;
      }
    }

    this.autoCompleteOptions = of(this.config.autoCompleteOptions);

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorTextConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createTextEditorValidator(this.config);
          this.autoCompleteOptions = of(this.config.autoCompleteOptions);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    this.propagateTouched();
  }

  onChange() {
    if (this.modelUpdateOn !== ModelUpdateMode.blur) {
      if (this.valueChange.observers.length > 0) {
        this.valueChange.emit(this.value);
      }

      this.swap.editorEvent(
        new EditorEvent(
          'change',
          this.config.attributeName,
          this.currentID,
          this.currentType,
          this.value
        )
      );
    }
  }

  onModelChange() {
    if (this.modelUpdateOn === ModelUpdateMode.blur) {
      if (this.valueChange.observers.length > 0) {
        this.valueChange.emit(this.value);
      }

      this.swap.editorEvent(
        new EditorEvent(
          'change',
          this.config.attributeName,
          this.currentID,
          this.currentType,
          this.value
        )
      );
    }
  }

  onValidationTagClick(name: string, index: number, e: Event) {
    e.stopPropagation();

    if (this.config.readOnly || this.disabled(this.resource.rightSets)) {
      return;
    }
    const pos = this.validationSetting.tags.findIndex(
      (t: ValidationTag) => t.name === name
    );
    if (pos >= 0) {
      if (this.validationSetting.exclusive) {
        this.validationSetting.tags.forEach(
          (t: ValidationTag, index: number) => {
            if (index !== pos) {
              t.isActive = false;
            }
          }
        );
      }
      if (this.validationSetting.canDeactivateAll) {
        this.validationSetting.tags[pos].isActive =
          !this.validationSetting.tags[pos].isActive;
      } else {
        let lastActive = true;
        for (const [i, v] of this.validationSetting.tags.entries()) {
          if (v.isActive && i !== pos) {
            lastActive = false;
            break;
          }
        }
        if (!lastActive || !this.validationSetting.tags[pos].isActive) {
          this.validationSetting.tags[pos].isActive =
            !this.validationSetting.tags[pos].isActive;
        }
      }

      this.validateValue();
    }
  }

  onMouseOutTag() {
    if (this.tagMenuTriggers) {
      this.tagMenuTriggers.forEach((t) => t.closeMenu());
    }
  }

  onDelayedHoverTag(index: number) {
    if (this.tagMenuTriggers) {
      this.tagMenuTriggers.toArray()[index].openMenu();
    }
  }

  onHelpClickTag(url: string) {
    window.open(url, '_blank');
  }

  onStartEdit() {
    this.simpleEditing = true;
  }

  // #endregion

  // #region Public methods

  setAutoCompleteOptions(
    options: Observable<Array<{ text: string; value: string }>>
  ) {
    this.autoCompleteOptions = options;
  }

  openAutoComplete() {
    if (this.autoCompleteTrigger) {
      this.autoCompleteTrigger.openPanel();
    }
  }

  closeAutoComplete() {
    if (this.autoCompleteTrigger) {
      this.autoCompleteTrigger.closePanel();
    }
  }

  // #endregion
}
