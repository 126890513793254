import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

class UpdateEntry {
  allownull?: boolean;
  target?: string;
  type?: string;
  valueexpression?: string;
}

@Component({
  selector: 'app-activity-update-resource',
  templateUrl: './activity-update-resource.component.html',
  styleUrls: ['./activity-update-resource.component.scss'],
})
export class ActivityUpdateResourceComponent extends WorkflowActivityView {
  disablePostTrigger: boolean;
  disablePreTrigger: boolean;
  requestorAsActor: boolean;
  skipPermission: boolean;
  entries: Array<UpdateEntry>;

  @Input()
  get disablePreupdateTrigger() {
    return this.disablePreTrigger;
  }
  set disablePreupdateTrigger(value: boolean) {
    this.disablePreTrigger = value;
    this.disablePreupdateTriggerChange.emit(this.disablePreTrigger);
  }
  @Output()
  disablePreupdateTriggerChange = new EventEmitter();

  @Input()
  get disablePostupdateTrigger() {
    return this.disablePostTrigger;
  }
  set disablePostupdateTrigger(value: boolean) {
    this.disablePostTrigger = value;
    this.disablePostupdateTriggerChange.emit(this.disablePostTrigger);
  }
  @Output()
  disablePostupdateTriggerChange = new EventEmitter();

  @Input()
  get useRequestorAsActor() {
    return this.requestorAsActor;
  }
  set useRequestorAsActor(value: boolean) {
    this.requestorAsActor = value;
    this.useRequestorAsActorChange.emit(this.requestorAsActor);
  }
  @Output()
  useRequestorAsActorChange = new EventEmitter();

  @Input()
  get skipPermissionCheck() {
    return this.skipPermission;
  }
  set skipPermissionCheck(value: boolean) {
    this.skipPermission = value;
    this.skipPermissionCheckChange.emit(this.skipPermission);
  }
  @Output()
  skipPermissionCheckChange = new EventEmitter();

  @Input()
  get updateResourcesEntries() {
    return this.entries;
  }
  set updateResourcesEntries(value: Array<UpdateEntry>) {
    this.entries = value;
    this.updateResourcesEntriesChange.emit(this.entries);
  }
  @Output()
  updateResourcesEntriesChange = new EventEmitter();

  constructor(private dragula: DragulaService) {
    super();

    try {
      this.dragula.createGroup('UPDATERESOURCESENTRIES', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  onAddEntry() {
    if (this.entries) {
      this.entries.push({
        allownull: false,
        type: 'UpdateResourcesEntry',
        target: '',
        valueexpression: '',
      });
    } else {
      this.entries = [
        {
          allownull: false,
          type: 'UpdateResourcesEntry',
          target: '',
          valueexpression: '',
        },
      ];
    }
  }

  onRemoveEntry(index: number) {
    this.entries.splice(index, 1);
  }

  onImpersonationChange() {
    if (!this.useRequestorAsActor) {
      this.skipPermissionCheck = false;
    }
  }
}
