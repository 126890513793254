import { Component, OnInit, OnDestroy } from '@angular/core';

import { EMPTY, forkJoin, of, Subscription } from 'rxjs';
import { switchMap, tap, finalize, catchError } from 'rxjs/operators';
import {
  SelectEvent,
  FileInfo,
  FileRestrictions,
} from '@progress/kendo-angular-upload';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

import { ModalType } from '../core/models/componentContract.model';
import {
  Resource,
  BasicResource,
  AuthMode,
  ResourceSet,
  PopupConfig,
  BulkImportDef,
  ImportItemDef,
  ImportAttributeDef,
  ImportItem,
} from '../core/models/dataContract.model';

import { ResourceService } from '../core/services/resource.service';
import { TransService } from '../core/models/translation.model';
import { SwapService } from '../core/services/swap.service';
import { UtilsService } from '../core/services/utils.service';
import { ModalService } from '../core/services/modal.service';
import { ConfigService } from '../core/services/config.service';
import { AuthService } from '../core/services/auth.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ComponentService } from '../core/services/component.service';
import {
  VerifiedIdData,
  VerifiedIdService,
} from '../services/verified-id.service';
import { SignalService } from '../core/services/signal.service';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  loginUser: Resource;
  brandLetter = '';
  attrPhoto: string;

  photoRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png'],
  };
  importRestrictions: FileRestrictions = {
    allowedExtensions: ['.json'],
  };

  primaryViewSet: BasicResource;
  availableViewSets: BasicResource[];
  selectedViewSetID = '';

  customViewSetting: any;
  primaryViewSetting: any;

  availableWizards: Array<{
    type: string;
    objectType: string;
    icon: string;
  }> = [];
  selectedWizard: { type: string; objectType: string; icon: string };
  newWizardName: string;
  newWizardIcon: string;
  showWizard: boolean;

  availableForms: Array<string> = [];
  selectedForm: string;
  newFormName: string;
  newFormIcon: string;

  get hasAdminRight() {
    return this.resource.isAdminViewSet;
  }

  textAdminRightSet: string;
  colorAdminRightSet: string;

  canManageSelectedRightSet = this.hasAdminRight;
  textCurrentRightSet: string;
  colorCurrentRightSet: string;

  currentLanguage: string;
  allLanguages = this.config.getConfig('supportedLanguages');

  previewEnabled = false;

  sharePhotoEnabled: boolean;

  spinnerType = SPINNER;
  loaderUserSettings = 'loader_userSettings';
  loaderUiGroups = 'loader_uiGroups';
  loaderManageWizard = 'loader_manageWizard';
  loaderManageForm = 'loader_manageForm';

  authenticationMode: AuthMode;
  selectAllTypes = false;

  exportResourceTypes: Array<{
    name: string;
    type: string;
    query: string;
    attributes?: string[];
    selected: boolean;
  }> = [];
  exportConfigTypes: Array<{
    name: string;
    type: string;
    query: string;
    attributes?: string[];
    selected: boolean;
  }> = [];
  exportSchemaTypes: Array<{
    name: string;
    type: string;
    query: string;
    attributes?: string[];
    selected: boolean;
  }> = [];

  blurLevel = this.config.getConfig('blurLevel', 1);

  dsVersion = this.resource.dataServiceVersion;
  uiVersion = this.com.getCustomVersion();
  coreVersion = `${this.utils.version}${this.utils.buildNumber} ${this.utils.enviroment}`;
  buildNumber = this.utils.buildNumber;

  isCloud = this.resource.authenticationMode === AuthMode.azure;

  issuanceQR = '';
  issuanceError = '';
  issuanceState = '';
  issuanceCompleted = false;
  issuanceLoading = false;
  issuanceTimeout: NodeJS.Timeout;

  enableVerifiedId = false;

  bulkImportDef: BulkImportDef;

  constructor(
    private resource: ResourceService,
    private translate: TransService,
    private swap: SwapService,
    private utils: UtilsService,
    private spinner: NgxUiLoaderService,
    private modal: ModalService,
    private config: ConfigService,
    private auth: AuthService,
    private com: ComponentService,
    private vid: VerifiedIdService,
    private signal: SignalService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar
  ) {}

  private initUiGroups() {
    this.selectedViewSetID = '';

    this.primaryViewSet = this.resource.primaryViewSet;
    this.availableViewSets = this.resource.viewSets;

    if (
      !this.availableViewSets.find(
        (s) =>
          s.ObjectID.toLowerCase() ===
          this.resource.standardViewSet.ObjectID.toLowerCase()
      )
    ) {
      this.availableViewSets.unshift(this.resource.standardViewSet);
    }

    if (this.resource.isAdminViewSet) {
      this.textAdminRightSet = 'l10n_hasAdminRight';
      this.colorAdminRightSet = 'seagreen';
    } else {
      this.textAdminRightSet = 'l10n_hasNoAdminRight';
      this.colorAdminRightSet = 'coral';
    }
  }

  private initWizards() {
    const viewSetting = this.resource.primaryViewSetting.creationView;
    if (viewSetting) {
      this.availableWizards.splice(0, this.availableWizards.length);
      Object.keys(viewSetting).forEach((setting) => {
        this.availableWizards.push({
          type: viewSetting[setting].type,
          objectType: viewSetting[setting].objectType,
          icon: viewSetting[setting].icon,
        });
      });
    } else {
      this.availableWizards = [];
    }
  }

  private initForms() {
    const viewSetting = this.resource.primaryViewSetting.editingView;
    if (viewSetting) {
      this.availableForms.splice(0, this.availableForms.length);
      Object.keys(viewSetting).forEach((settingName) => {
        this.availableForms.push(settingName);
      });
    } else {
      this.availableForms = [];
    }
  }

  private downloadFile(text: string) {
    const filename = 'bulkimport.log';
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  private validateBulkImportProperty(
    propertyFromDef: string[],
    propertyFromCsv: string[]
  ) {
    if (!propertyFromCsv || propertyFromCsv.length !== propertyFromDef.length) {
      return false;
    }
    for (let index = 0; index < propertyFromDef.length; index++) {
      if (
        !propertyFromCsv[index] ||
        propertyFromCsv[index].toLowerCase() !==
          propertyFromDef[index].toLowerCase()
      ) {
        return false;
      }
    }
    return true;
  }

  private buildBulkImportItem(
    separator: string,
    objectType: string,
    attributeDefs: Array<ImportAttributeDef>,
    valueFromCsv: string
  ): ImportItem {
    const valuesFromCsv = valueFromCsv.split(separator);
    if (valuesFromCsv.length !== attributeDefs.length) {
      return {
        hasError: true,
      };
    }
    const uniquenessChecks: string[] = [];
    const objectToImport: Resource = {
      ObjectType: objectType,
    };
    for (let i = 0; i < attributeDefs.length; i++) {
      const csvValue =
        valuesFromCsv[i] === undefined ? '' : valuesFromCsv[i].trim();
      if (csvValue === '' || csvValue === undefined) {
        if (attributeDefs[i].isRequired) {
          return {
            hasError: true,
            errorMsg: 'key_valueRequired',
          };
        }
      } else {
        if (attributeDefs[i].type === 'number' && isNaN(Number(csvValue))) {
          return {
            hasError: true,
            errorMsg: 'key_cannotParseToNumber',
          };
        }
        if (
          attributeDefs[i].type === 'boolean' &&
          !/(true|false)/i.test(csvValue)
        ) {
          return {
            hasError: true,
            errorMsg: 'key_cannotParseToBoolean',
          };
        }
        if (attributeDefs[i].validation) {
          if (!new RegExp(attributeDefs[i].validation).test(csvValue)) {
            return {
              hasError: true,
              errorMsg: 'key_cannotPassValidation',
            };
          }
        }
        if (attributeDefs[i].isRequired) {
          uniquenessChecks.push(`${attributeDefs[i].name}='${csvValue}'`);
        }

        objectToImport[attributeDefs[i].name] = csvValue;
      }
    }

    return {
      hasError: false,
      uniquenessCheck:
        uniquenessChecks.length > 0 ? uniquenessChecks.join(' or ') : '',
      objectToImport: objectToImport,
    };
  }

  ngOnInit() {
    this.authenticationMode = this.resource.authenticationMode;

    this.enableVerifiedId = this.config.getConfig('enableVerifiedId', false);

    this.bulkImportDef = this.config.getConfig('bulkImport', null);

    this.customViewSetting = this.resource.customViewSetting;
    this.primaryViewSetting = this.resource.primaryViewSetting;

    this.currentLanguage = this.customViewSetting.language;
    this.sharePhotoEnabled = this.utils.ExtraValue(
      this.resource.loginUser,
      'ocgObjectSource'
    );

    if (this.customViewSetting.previewEnabled !== undefined) {
      this.previewEnabled = this.customViewSetting.previewEnabled;
    }

    this.initForms();
    this.initWizards();

    this.loginUser = this.resource.loginUser;
    if (this.loginUser) {
      this.brandLetter = this.utils.ExamValue(this.loginUser, 'DisplayName')
        ? this.utils.ExtraValue(this.loginUser, 'DisplayName').substr(0, 1)
        : '-';
      this.attrPhoto = this.utils.ExtraValue(this.loginUser, 'Photo');
    }

    const exportSettings = this.config.getConfig('exportSettings', undefined);
    if (exportSettings) {
      this.exportResourceTypes = exportSettings.resource
        ? exportSettings.resource
        : [];
      this.exportConfigTypes = exportSettings.config
        ? exportSettings.config
        : [];
      this.exportSchemaTypes = exportSettings.schema
        ? exportSettings.schema
        : [];
    }

    this.initUiGroups();

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    this.subscription.add(
      this.signal
        .startConnection()
        .pipe(
          switchMap(() => {
            return this.signal.getConnectionId();
          }),
          tap(() => {
            this.signal.addListener('IssuanceRetrieved', () => {
              this.issuanceState =
                'Please use the authenticator to save your verified ID';
            });
            this.signal.addListener('IssuanceCompleted', () => {
              this.issuanceCompleted = true;
              this.issuanceState = 'Verified ID successfully issued';
            });
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.signal.close();
  }

  hasExports() {
    const counterResourceTypes = this.exportResourceTypes.findIndex(
      (e) => e.selected === true
    );
    const counterConfigTypes = this.exportConfigTypes.findIndex(
      (e) => e.selected === true
    );
    const counterSchemaTypes = this.exportSchemaTypes.findIndex(
      (e) => e.selected === true
    );

    if (
      counterResourceTypes >= 0 ||
      counterConfigTypes >= 0 ||
      counterSchemaTypes >= 0
    ) {
      return true;
    }

    return false;
  }

  onPhotoSelected(ev: SelectEvent) {
    if (ev.files[0].extension !== '.jpg' && ev.files[0].extension !== '.png') {
      this.modal.show(ModalType.error, 'key_error', 'l10n_fileTypeNotAllowed');
      return;
    }

    ev.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          this.spinner.startLoader(this.loaderUserSettings);

          const strPhoto = reader.result as string;
          const resourceToUpdate = {
            ObjectID: this.utils.ExtraValue(this.loginUser, 'ObjectID'),
            ObjectType: this.utils.ExtraValue(this.loginUser, 'ObjectType'),
            Photo: strPhoto.substr(strPhoto.indexOf(',') + 1),
          };

          this.subscription.add(
            this.resource
              .updateResource(resourceToUpdate)
              .pipe(
                tap((result: HttpResponse<string>) => {
                  if (result) {
                    if (result.status === 202) {
                      this.modal.show(
                        ModalType.info,
                        'key_info',
                        'key_approvalRequired'
                      );
                    } else if (result.status === 200) {
                      this.attrPhoto = resourceToUpdate.Photo;
                      this.loginUser.Photo = this.attrPhoto;
                      this.resource.loginUser.Photo = this.attrPhoto;
                      this.swap.broadcast({
                        name: 'refresh-avatar',
                        parameter: null,
                      });
                    }
                  }
                }),
                finalize(() => {
                  this.spinner.stopLoader(this.loaderUserSettings);
                })
              )
              .subscribe()
          );
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  onPhotoDeleted() {
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_confirmDeletePhoto'
    );

    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((dialogResult: string) => {
            if (dialogResult && dialogResult === 'yes') {
              this.spinner.startLoader(this.loaderUserSettings);

              const resourceToUpdate = {
                ObjectID: this.utils.ExtraValue(this.loginUser, 'ObjectID'),
                ObjectType: this.utils.ExtraValue(this.loginUser, 'ObjectType'),
                Photo: '',
              };
              return this.resource.updateResource(resourceToUpdate);
            } else {
              return EMPTY;
            }
          }),
          tap((result: HttpResponse<string>) => {
            if (result) {
              if (result.status === 202) {
                this.modal.show(
                  ModalType.info,
                  'key_info',
                  'key_approvalRequired'
                );
              } else if (result.status === 200) {
                this.attrPhoto = '';
                this.loginUser.Photo = this.attrPhoto;
                this.resource.loginUser.Photo = this.attrPhoto;
                this.swap.broadcast({
                  name: 'refresh-avatar',
                  parameter: null,
                });
              }
            }
          }),
          finalize(() => {
            this.spinner.stopLoader(this.loaderUserSettings);
          })
        )
        .subscribe()
    );
  }

  onDeleteCache() {
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_confirmDeleteCache'
    );
    confirm.afterClosed().subscribe((result) => {
      if (result && result === 'yes') {
        this.auth.logout(true);
      }
    });
  }

  onLanguageChanged(language: string) {
    this.spinner.startLoader(this.loaderUserSettings);

    this.customViewSetting.language = language;

    const objectToSave = {
      ObjectID: this.utils.ExtraValue(this.resource.loginUser, 'ObjectID'),
    };
    objectToSave[this.utils.attConfiguration] = JSON.stringify(
      this.customViewSetting
    );
    this.resource.updateResource(objectToSave, true).subscribe(
      () => {
        this.currentLanguage = language;
        this.swap.currentLanguage = language;
        this.translate.use(language).subscribe(() => {
          this.swap.broadcast({
            name: 'refresh-language',
            parameter: language,
          });
        });
        this.spinner.stopLoader(this.loaderUserSettings);
      },
      () => {
        this.spinner.stopLoader(this.loaderUserSettings);
      }
    );
  }

  onSharePhotoChanged() {
    this.spinner.startLoader(this.loaderUserSettings);
    this.loginUser.ocgObjectSource = this.sharePhotoEnabled;
    this.subscription.add(
      this.resource.updateResource(this.resource.loginUser).subscribe(
        () => {
          this.spinner.stopLoader(this.loaderUserSettings);
        },
        () => {
          this.spinner.stopLoader(this.loaderUserSettings);
        }
      )
    );
  }

  onPreviewChanged() {
    this.spinner.startLoader(this.loaderUserSettings);

    this.customViewSetting.previewEnabled = this.previewEnabled;

    const objectToSave = {
      ObjectID: this.utils.ExtraValue(this.resource.loginUser, 'ObjectID'),
    };
    objectToSave[this.utils.attConfiguration] = JSON.stringify(
      this.customViewSetting
    );

    this.subscription.add(
      this.resource.updateResource(objectToSave).subscribe(
        () => {
          this.spinner.stopLoader(this.loaderUserSettings);
        },
        () => {
          this.spinner.stopLoader(this.loaderUserSettings);
        }
      )
    );
  }

  onUiGroupSelectionChanged() {
    if (this.selectedViewSetID) {
      if (
        this.resource.isAdminViewSet ||
        this.resource.adminViewSets.find(
          (s) =>
            s.ObjectID.toLowerCase() === this.selectedViewSetID.toLowerCase()
        )
      ) {
        this.canManageSelectedRightSet = true;
        this.textCurrentRightSet = 'l10n_hasAdminRight';
        this.colorCurrentRightSet = 'seagreen';
      } else {
        this.canManageSelectedRightSet = false;
        this.textCurrentRightSet = 'l10n_hasNoAdminRight';
        this.colorCurrentRightSet = 'coral';
      }
    } else {
      this.canManageSelectedRightSet = this.hasAdminRight;
    }
  }

  onApplyUiGroup() {
    this.spinner.startLoader(this.loaderUiGroups);

    const selectedViewSet = this.availableViewSets.find(
      (s) => s.ObjectID.toLowerCase() === this.selectedViewSetID.toLowerCase()
    );
    if (selectedViewSet) {
      this.resource.loginUser[this.utils.attPrimaryViewSets] =
        selectedViewSet.ObjectID;
    }
    this.resource
      .updateResource(this.resource.loginUser, true)
      .pipe(
        switchMap(() => {
          return this.resource
            .getResourceByID(
              this.selectedViewSetID,
              [this.utils.attConfiguration],
              'simple',
              '',
              'false',
              true
            )
            .pipe(
              tap((uiSet: Resource) => {
                this.resource.primaryViewSetting =
                  this.utils.parseComponentConfig(
                    uiSet[this.utils.attConfiguration]
                  );
                location.reload();
              })
            );
        })
      )
      .subscribe(
        () => {
          this.resource.primaryViewSet = selectedViewSet;
          this.resource.checkCurrentViewSet();
          this.initUiGroups();
          this.swap.broadcast({ name: 'refresh-viewset', parameter: null });
          this.spinner.stopLoader(this.loaderUiGroups);
        },
        () => {
          this.spinner.stopLoader(this.loaderUiGroups);
        }
      );
  }

  onSelectAllTypes() {
    if (this.selectAllTypes) {
      this.exportResourceTypes.map((r) => {
        r.selected = true;
        return r;
      });
      this.exportConfigTypes.map((r) => {
        r.selected = true;
        return r;
      });
      this.exportSchemaTypes.map((r) => {
        r.selected = true;
        return r;
      });
    } else {
      this.exportResourceTypes.map((r) => {
        r.selected = false;
        return r;
      });
      this.exportConfigTypes.map((r) => {
        r.selected = false;
        return r;
      });
      this.exportSchemaTypes.map((r) => {
        r.selected = false;
        return r;
      });
    }
  }

  onExportResources() {
    const progress = this.modal.show(
      ModalType.progress,
      'l10n_exportingData',
      '',
      '300px'
    );

    const observableBatch = [];
    this.exportResourceTypes.forEach((e) => {
      if (e.selected) {
        observableBatch.push(
          this.resource.getResourceByQuery(e.query, e.attributes ?? [])
        );
      }
    });
    this.exportConfigTypes.forEach((e) => {
      if (e.selected) {
        observableBatch.push(
          this.resource.getResourceByQuery(e.query, e.attributes ?? [])
        );
      }
    });
    this.exportSchemaTypes.forEach((e) => {
      if (e.selected) {
        observableBatch.push(
          this.resource.getResourceByQuery(e.query, e.attributes ?? [])
        );
      }
    });

    if (observableBatch.length > 0) {
      forkJoin(observableBatch).subscribe(
        (result) => {
          let exportData: Array<Resource> = [];
          if (result && result.length > 0) {
            result.forEach((r: ResourceSet) => {
              exportData = exportData.concat(r.results);
            });
          }
          // const blob = new Blob([JSON.stringify(exportData)], {
          //   type: 'application/json;charset=utf-8'
          // });
          const dataUri =
            'data:text/plain;base64,' +
            encodeBase64(JSON.stringify(exportData));
          saveAs(dataUri, 'export.json');
          progress.close();
        },
        (error) => {
          progress.close();
          this.modal.show(ModalType.error, 'key_error', error);
        }
      );
    }
  }

  onImportResourcesNative(ev) {
    const fileList: FileList = ev.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];

      const exportConfigTypes = this.exportConfigTypes
        .map((e) => e.type)
        .join(',');
      const exportResourceTypes = this.exportResourceTypes
        .map((e) => e.type)
        .join(',');
      const exportSchemaTypes = this.exportSchemaTypes
        .map((e) => e.type)
        .join(',');
      const exportTypes = `${exportConfigTypes},${exportResourceTypes},${exportSchemaTypes}`;

      this.resource
        .importResourceFromFile(file, exportTypes, 'Creator', '', true)
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onImportResources(ev: SelectEvent) {
    if (ev.files[0].extension !== '.json') {
      this.modal.show(ModalType.error, 'key_error', 'l10n_fileTypeNotAllowed');
      return;
    }

    const progress = this.modal.show(
      ModalType.progress,
      'l10n_importingData',
      '',
      '300px'
    );

    ev.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const exportConfigTypes = this.exportConfigTypes
            .map((e) => e.type)
            .join(',');
          const exportResourceTypes = this.exportResourceTypes
            .map((e) => e.type)
            .join(',');
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const exportSchemaTypes = this.exportSchemaTypes
            .map((e) => e.type)
            .join(',');
          const exportTypes = `${exportConfigTypes},${exportResourceTypes}`;

          this.resource
            .importResourceFromFile(
              file.rawFile,
              exportTypes,
              'Creator',
              '',
              false
            )
            .subscribe(
              (data) => {
                if (data) {
                  if (data.errors && data.errors.length > 0) {
                    this.modal.show(
                      ModalType.error,
                      'key_error',
                      data.errors[0]
                    );
                  } else if (data.warnings && data.warnings.length > 0) {
                    this.modal.show(
                      ModalType.info,
                      'key_warning',
                      data.warnings[0]
                    );
                  } else {
                    const importResult = JSON.parse(reader.result.toString());
                    let importSummary =
                      this.translate.instant('l10n_importSucceeded') + '<br/>';
                    this.exportResourceTypes.forEach((e) => {
                      const typeFound = importResult.filter(
                        (f: any) => f.ObjectType === e.type
                      );
                      if (typeFound && typeFound.length > 0) {
                        importSummary = `${importSummary}<br/>${this.translate.instant(
                          e.name
                        )}: ${typeFound.length} ${this.translate.instant(
                          'l10n_objectsImported'
                        )}`;
                      }
                    });
                    this.exportConfigTypes.forEach((e) => {
                      const typeFound = importResult.filter(
                        (f: any) => f.ObjectType === e.type
                      );
                      if (typeFound && typeFound.length > 0) {
                        importSummary = `${importSummary}<br/>${this.translate.instant(
                          e.name
                        )}: ${typeFound.length} ${this.translate.instant(
                          'l10n_objectsImported'
                        )}`;
                      }
                    });
                    this.exportSchemaTypes.forEach((e) => {
                      const typeFound = importResult.filter(
                        (f: any) => f.ObjectType === e.type
                      );
                      if (typeFound && typeFound.length > 0) {
                        importSummary = `${importSummary}<br/>${this.translate.instant(
                          e.name
                        )}: ${typeFound.length} ${this.translate.instant(
                          'l10n_objectsImported'
                        )}`;
                      }
                    });
                    importSummary += '<br/><br/>';
                    this.modal.show(
                      ModalType.info,
                      'key_finished',
                      importSummary,
                      '400px'
                    );
                  }
                }
                progress.close();
              },
              (error: HttpErrorResponse) => {
                progress.close();
                this.modal.show(ModalType.error, 'key_error', error.error);
              }
            );
        };

        reader.readAsText(file.rawFile);
      }
    });
  }

  onAcquireVid() {
    if (this.issuanceTimeout) {
      clearTimeout(this.issuanceTimeout);
    }

    this.issuanceQR = '';
    this.issuanceError = '';
    this.issuanceState = '';
    this.issuanceCompleted = false;
    this.issuanceLoading = true;

    this.subscription.add(
      this.vid
        .issuance(this.signal.connectionId)
        .pipe(
          tap((data: VerifiedIdData) => {
            if (data && data.qrCode) {
              this.issuanceQR = data.qrCode;
              this.issuanceTimeout = setTimeout(() => {
                if (!this.issuanceCompleted) {
                  this.issuanceError =
                    'Issuance timeout, please try it later again';
                }
              }, 180000);
            } else {
              this.issuanceError = 'No issuance QR code was found';
            }
          }),
          finalize(() => {
            this.issuanceLoading = false;
          }),
          catchError((err: string) => {
            this.issuanceError = err;
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onExportSettings() {
    if (!this.selectedViewSetID) {
      this.clipboard.copy(
        this.utils.stringifyComponentConfig(this.resource.primaryViewSetting)
      );
    } else {
      const process = this.modal.show(
        ModalType.progress,
        'key_savingChanges',
        '',
        '300px'
      );

      this.subscription.add(
        this.resource
          .getResourceByID(this.selectedViewSetID, [
            this.utils.attConfiguration,
          ])
          .pipe(
            tap((res: Resource) => {
              if (res) {
                const strSettings = this.utils.ExtraValue(
                  res,
                  this.utils.attConfiguration
                );
                this.clipboard.copy(strSettings);
              }
            }),
            finalize(() => {
              if (process) {
                process.close();
              }
            })
          )
          .subscribe()
      );
    }
  }

  onImportSettings() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_import';
    popupConfig.style = 'outline';
    popupConfig.data = {
      settings: {
        text: 'key_settings',
        value: '',
        type: 'textarea',
        rows: 6,
        required: true,
        focused: true,
      },
    };

    let settingsToImport: any;
    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              if (
                windowResult &&
                windowResult.settings &&
                windowResult.settings.value
              ) {
                if (
                  this.utils.validateUISettings(windowResult.settings.value)
                ) {
                  settingsToImport = JSON.parse(windowResult.settings.value);
                  return this.modal
                    .show(
                      ModalType.confirm,
                      'key_confirmation',
                      'key_confirmImportSettings'
                    )
                    .afterClosed();
                } else {
                  this.modal.show(
                    ModalType.error,
                    'key_error',
                    'key_invalidSetting'
                  );
                  return EMPTY;
                }
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidSetting'
                );
              }
            }
            return EMPTY;
          }),
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              this.resource.primaryViewSetting = settingsToImport;
              process = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              location.reload();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }

  onBulkImport(event: any, importDef: ImportItemDef) {
    if (event.target && event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);

      const process: MatDialogRef<ModalComponent, any> = this.modal.show(
        ModalType.progress,
        'key_processing',
        '',
        '300px'
      );

      reader.onload = () => {
        const csvText: string = reader.result as string;
        const propertyNames = csvText
          .slice(0, csvText.indexOf('\n'))
          .split(',');
        const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');

        if (
          !this.validateBulkImportProperty(
            importDef.attributes.map((a) => a.name),
            propertyNames
          )
        ) {
          if (process) {
            process.close();
          }
          this.modal.show(
            ModalType.error,
            'key_error',
            'key_importAttributesNotMatch'
          );
        } else {
          const obs = [];
          const results: Array<string> = [];

          // this.swap.broadcast({
          //   name: 'modal-title-update',
          //   parameter: [
          //     { key: 'Loading file', value: 'Imported __ resources' },
          //   ],
          // });

          dataRows.forEach((row: string, index: number) => {
            const importItem = this.buildBulkImportItem(
              importDef.separator || ',',
              importDef.objectType,
              importDef.attributes,
              row
            );
            if (importItem.hasError) {
              results.push(
                `${this.translate.instant('key_line')} ${
                  index + 2
                }: ${this.translate.instant(importItem.errorMsg)}`
              );
            } else {
              if (importItem.uniquenessCheck) {
                obs.push(
                  this.resource
                    .getResourceByQuery(
                      `/${importDef.objectType}[${importItem.uniquenessCheck}]`,
                      ['DisplayName']
                    )
                    .pipe(
                      switchMap((checkResult: ResourceSet) => {
                        if (
                          checkResult.results &&
                          checkResult.results.length > 0
                        ) {
                          results.push(
                            `${this.translate.instant('key_line')} ${
                              index + 2
                            }: ${this.translate.instant('key_valueNotUnique')}`
                          );
                          return of(null);
                        } else {
                          return this.resource.createResource(
                            importItem.objectToImport
                          );
                        }
                      }),
                      // tap((creationResult: any) => {
                      //   if (creationResult) {
                      //     this.swap.broadcast({
                      //       name: 'modal-title-update',
                      //       parameter: [
                      //         {
                      //           key: '__',
                      //           value: index + 1,
                      //         },
                      //       ],
                      //     });
                      //   }
                      // }),
                      catchError(() => {
                        results.push(
                          `${this.translate.instant('key_line')} ${
                            index + 2
                          }: ${this.translate.instant('key_errorWhenCreating')}`
                        );
                        return of(null);
                      })
                    )
                );
              } else {
                obs.push(
                  this.resource.createResource(importItem.objectToImport)
                );
              }
            }
          });
          this.subscription.add(
            forkJoin(obs)
              .pipe(
                finalize(() => {
                  this.downloadFile(results.join('\n'));
                  if (process) {
                    process.close();
                  }
                })
              )
              .subscribe()
          );
        }

        // reset the file uploader
        event.target.value = '';
      };

      reader.onerror = () => {
        if (process) {
          process.close();
        }
        this.modal.show(ModalType.error, 'key_error', 'key_unexpectedError');
      };
    }
  }
}
