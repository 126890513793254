import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Subscription, Observable, EMPTY, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ConfigService } from '../core/services/config.service';
import { TransService } from '../core/models/translation.model';
import { ResourceService } from '../core/services/resource.service';
import { SwapService } from '../core/services/swap.service';
import { UtilsService } from '../core/services/utils.service';
import { AuthService } from '../core/services/auth.service';
import { AuthMode } from '../core/models/dataContract.model';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  sub: Subscription;
  msalSub: Subscription;

  imageSource = this.config.getConfigEx(
    'images:logoSplash',
    'assets/img/ocglogoblack.png'
  );
  imageHeight = this.config.getConfigEx('images:logoSplashHeight', 80);

  bgColor = this.config.getConfigEx('images:bgColorSplash', '#2f2f2e');
  bgColorLoading = this.config.getConfigEx(
    'images:bgColorSplashLoading',
    '#fffafa'
  );

  private navigateToPath(path: string) {
    if (path) {
      if (path.toLowerCase() === '/app/profile') {
        if (this.resource.loginUser) {
          const loginId = this.utils.ExtraValue(
            this.resource.loginUser,
            'ObjectID'
          );
          if (loginId) {
            this.router.navigate([`/app/form/person/${loginId}`]);
          } else {
            this.router.navigate(['/app']);
          }
        } else {
          this.router.navigate(['/app']);
        }
      } else {
        this.router.navigate([path]);
      }
    } else {
      this.router.navigate(['/app']);
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: ConfigService,
    private translate: TransService,
    private resource: ResourceService,
    private swap: SwapService,
    private utils: UtilsService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    const startPath = this.config.getConfig('startPath', '/app');

    this.swap.editMode = false;

    const obs: Observable<Params> = this.resource
      .load(this.resource.accessConnection)
      .pipe(
        switchMap(() => {
          return this.resource.getCurrentUser().pipe(
            tap(() => {
              this.resource.customViewSetting = this.utils.parseComponentConfig(
                this.resource.customViewString
              );
              this.swap.currentLanguage =
                this.resource.customViewSetting.language;
              this.translate.use(this.resource.customViewSetting.language);
            })
          );
        }),
        switchMap(() => {
          if (this.config.getConfig('maintenanceMode', false)) {
            const loginExceptions: string[] = this.config.getConfig(
              'maintenanceExceptions',
              []
            );
            const loginAccount: string = this.utils.ExtraValue(
              this.resource.loginUser,
              'AccountName'
            );
            if (loginAccount) {
              const pos = loginExceptions.findIndex(
                (a: string) => a.toLowerCase() === loginAccount.toLowerCase()
              );
              if (pos < 0) {
                this.auth.logout(false, '/maintenance');
                return EMPTY;
              }
            } else {
              this.auth.logout(false, '/maintenance');
              return EMPTY;
            }
          }
          return this.resource.getUserConfig().pipe(
            tap(() => {
              this.resource.primaryViewSetting =
                this.utils.parseComponentConfig(
                  this.resource.primaryViewString
                );
            })
          );
        }),
        switchMap(() => {
          if (this.auth.authMode === AuthMode.azure) {
            return this.resource.getSystemMessage();
          } else {
            return of('');
          }
        }),
        switchMap((msg: string) => {
          if (msg) {
            setTimeout(() => {
              this.swap.broadcast({
                name: 'system-message',
                parameter: msg,
              });
            }, this.config.getConfig('intervalLarge', 1000));
          }
          return this.route.queryParams;
        })
      );

    this.sub = obs
      .pipe(
        tap((params) => {
          if (params.path) {
            const routePrefix = this.config.getConfig('routePrefix', '');

            let path: string = routePrefix
              ? params.path.toLowerCase().replace(routePrefix, '')
              : params.path.toLowerCase();
            if (!path || path === 'login' || path === '/login') {
              path = startPath;
            } else {
              const pos = path.indexOf('/sidenav:');
              if (pos > 0) {
                path = path.substring(0, pos).replace('(', '');
              }
            }

            this.navigateToPath(path);
          } else {
            this.navigateToPath(startPath);
          }
        }),
        catchError(() => {
          this.router.navigate(['/error']);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
