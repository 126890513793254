<div fxLayout="column" style="height: 100%">
  <form autocomplete="off" #activityGeneralForm="ngForm">
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field fxFlex="1 1 100">
        <input
          matInput
          [placeholder]="'key_name' | translate"
          [(ngModel)]="data.displayname"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field appearance="fill" fxFlex="1 1 100">
        <textarea
          matInput
          rows="2"
          [placeholder]="'key_description' | translate"
          [(ngModel)]="data.description"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
        <mat-label>{{ 'key_description' | translate }}</mat-label>
      </mat-form-field>
    </div>
    <div>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'key_advanced' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-slide-toggle
            fxFlex="1 1 33"
            [(ngModel)]="data.isenabled"
            [ngModelOptions]="{ standalone: true }"
          >
            {{ 'key_isEnabled' | translate }}</mat-slide-toggle
          >
          <mat-slide-toggle
            fxFlex="1 1 33"
            [(ngModel)]="data.continueonerror"
            [ngModelOptions]="{ standalone: true }"
          >
            {{ 'key_continueOnError' | translate }}</mat-slide-toggle
          >
          <mat-slide-toggle
            fxFlex="1 1 33"
            [(ngModel)]="detailMessageEnabled"
            (change)="onShowDebugInfoChange()"
            [ngModelOptions]="{ standalone: true }"
          >
            {{ 'key_showDebugInfo' | translate }}</mat-slide-toggle
          >
        </div>
        <div>
          <app-editor-text
            fxFlex="1 1 100"
            style="margin-top: 15px; margin-bottom: 20px"
            name="txtExecutionCondition"
            [(ngModel)]="data.executioncondition"
            [config]="{
              isSimpleValue: true,
              hintLimit: 80,
              customDisplayName: 'key_executionCondition',
              validationKey: 'workflow/AddDelay/executioncondition'
            }"
            appExpressionValidator
          ></app-editor-text>
        </div>
        <div>
          <app-editor-iterables
            #itrXpathQuery="ngModel"
            name="itrXpathQuery"
            [config]="configXpathQuery"
            [ngModel]="attrXpathQuery"
            (valueChange)="onXpathQueryChange()"
          ></app-editor-iterables>
        </div>
        <div style="margin-top: 20px">
          <app-editor-iterables
            #itrWorkflowData="ngModel"
            name="itrWorkflowData"
            [config]="configWorkflowExpression"
            [ngModel]="attrWorkflowData"
            (valueChange)="onWorkflowDataChange()"
          ></app-editor-iterables>
        </div>
      </mat-expansion-panel>
    </div>
  </form>
  <div [ngSwitch]="data.type" style="margin-top: 20px; margin-bottom: 20px">
    <app-activity-add-delay
      *ngSwitchCase="'AddDelay'"
      [(value)]="data.delay"
    ></app-activity-add-delay>
    <app-activity-for-each
      *ngSwitchCase="'ForEach'"
      [(listExpression)]="data.listexpression"
      [(currentItemKey)]="data.currentitemkey"
      [(breakExpression)]="data.breakexpression"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-for-each>
    <app-activity-update-resource
      *ngSwitchCase="'UpdateResources'"
      [(disablePreupdateTrigger)]="data.disablepreupdatetriggers"
      [(disablePostupdateTrigger)]="data.disablepostupdatetriggers"
      [(useRequestorAsActor)]="data.userequestorasactor"
      [(skipPermissionCheck)]="data.skippermissioncheck"
      [(updateResourcesEntries)]="data.updateresourcesentries"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-update-resource>
    <app-activity-create-resource
      *ngSwitchCase="'CreateResource'"
      [(disablePreupdateTrigger)]="data.disablepreupdatetriggers"
      [(disablePostupdateTrigger)]="data.disablepostupdatetriggers"
      [(useRequestorAsActor)]="data.userequestorasactor"
      [(skipPermissionCheck)]="data.skippermissioncheck"
      [(objectType)]="data.objecttype"
      [(createdResourceIDTarget)]="data.createdresourceidtarget"
      [(createResourceEntries)]="data.createresourceentries"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-create-resource>
    <app-activity-delete-resource
      *ngSwitchCase="'DeleteResources'"
      [(disablePreupdateTrigger)]="data.disablepreupdatetriggers"
      [(disablePostupdateTrigger)]="data.disablepostupdatetriggers"
      [(useRequestorAsActor)]="data.userequestorasactor"
      [(skipPermissionCheck)]="data.skippermissioncheck"
      [(deletionTarget)]="data.deletiontarget"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-delete-resource>
    <app-activity-rest-api-call
      *ngSwitchCase="'RestApiCall'"
      [(asyncCallBack)]="data.asynccallback"
      [(bodyExpression)]="data.bodyexpression"
      [(contentType)]="data.contenttype"
      [(expectedResponseCodes)]="data.expectedresponsecodes"
      [(headerExpression)]="data.headerexpressions"
      [(method)]="data.method"
      [(queryExpression)]="data.queryexpressions"
      [(responseKey)]="data.responsekey"
      [(urlExpression)]="data.urlexpression"
      [(authenticationType)]="data.authenticationtype"
      [(azureResourceId)]="data.azureresourceid"
      [(oauth2ClientId)]="data.oauth2clientid"
      [(oauth2ClientSecret)]="data.oauth2clientsecret"
      [(oauth2Scope)]="data.oauth2scope"
      [(oauth2TokenEndpoint)]="data.oauth2tokenendpoint"
      [(azureTenantId)]="data.azuretenantid"
      (activityValidationChange)="onActivityValidationChange($event)"
    >
    </app-activity-rest-api-call>
    <app-activity-send-email
      *ngSwitchCase="'SendEmail'"
      [(emailTemplate)]="data.emailtemplate"
      [(importance)]="data.importance"
      [(sendTo)]="data.to"
      [(sendCc)]="data.cc"
      [(sendBcc)]="data.bcc"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-send-email>
    <app-activity-approval
      *ngSwitchCase="'Approval'"
      [(approvalTitle)]="data.approvaltitle"
      [(approvalDescription)]="data.approvaldescription"
      [(durationByStage)]="data.durationbystage"
      [(approversByStage)]="data.approversbystage"
      [(approvalThreshold)]="data.approvalthreshold"
      [(disableAutoSelfApproval)]="data.disableautoselfapproval"
      [(emergencyApprovers)]="data.emergencyapprovers"
      [(pendingApprovalEmailTemplate)]="data.pendingapprovalemailtemplate"
      [(pendingApprovalEscalationEmailTemplate)]="
        data.pendingapprovalescalationemailtemplate
      "
      [(completedApprovalEmailTemplate)]="data.completedapprovalemailtemplate"
      [(rejectedRequestEmailTemplate)]="data.rejectedrequestemailtemplate"
      [(timedOutRequestEmailTemplate)]="data.timedoutrequestemailtemplate"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-approval>
    <app-activity-if-then-else
      *ngSwitchCase="'IfThenElse'"
      [(ifCondition)]="data.ifcondition"
      (activityValidationChange)="onActivityValidationChange($event)"
    ></app-activity-if-then-else>
    <app-activity-template
      *ngSwitchCase="'Workflow'"
      [(workflowTemplateId)]="data.workflowtemplateid"
      [(inputParameters)]="data.inputparameters"
    ></app-activity-template>
    <app-activity-dataflow
      *ngSwitchCase="'Dataflow'"
      [(dataflowRuleId)]="data.dataflowruleid"
      [(disableFurtherDataflowTriggering)]="
        data.disablefurtherdataflowtriggering
      "
    ></app-activity-dataflow>
    <app-activity-status
      *ngSwitchCase="'Status'"
      [(status)]="data.status"
    ></app-activity-status>
    <app-activity-placeholder
      *ngSwitchCase="'Placeholder'"
      [(originalActivity)]="data.originalactivity"
    ></app-activity-placeholder>
  </div>
  <div fxFlex="1 1 auto"></div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px; padding-bottom: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [disabled]="!isActivityValid || activityGeneralForm.invalid"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title [win]="win" [title]="typeName"></app-popup-title>
</ng-template>
