import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { BroadcastEvent } from '../core/models/dataContract.model';

import { SwapService } from '../core/services/swap.service';
import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  allResources: any;

  get resourceKeys() {
    return this.allResources ? Object.keys(this.allResources) : [];
  }

  constructor(
    private swap: SwapService,
    private config: ConfigService,
    private resource: ResourceService
  ) {}

  ngOnInit() {
    this.allResources = this.config.getConfig('allResources', {});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  permissionAllowed(entry: any) {
    if (entry && entry.permissionSets && entry.permissionSets.length > 0) {
      return this.resource.inPermissionSets(entry.permissionSets);
    }

    return true;
  }

  onPrimaryAction(actionName: string) {
    this.swap.cardEvent(new BroadcastEvent(actionName, 'primary'));
  }

  onSecondaryAction(actionName: string) {
    this.swap.cardEvent(new BroadcastEvent(actionName, 'secondary'));
  }
}
