<div *ngFor="let key of resourceKeys; let isFirst = first">
  <div
    *ngIf="allResources[key].enabled && permissionAllowed(allResources[key])"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="ie-absolut"
  >
    <div
      fxFlex="100%"
      class="setting-header"
      [ngClass]="{ 'additional-top': !isFirst }"
    >
      {{ allResources[key].name | translate }}
    </div>
    <div *ngFor="let action of allResources[key].items" fxFlex="none">
      <app-action-card
        *ngIf="action.enabled"
        [config]="action"
        (primaryAction)="onPrimaryAction(action.primaryAction)"
        (secondaryAction)="onSecondaryAction(action.secondaryAction)"
      ></app-action-card>
    </div>
  </div>
</div>
